import React, { useState , useEffect } from 'react';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEye, faDownload, faTimes , faTrash} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
// import ViewPDFButton from './ViewPDFButton';
import PdfViewer from './ViewPDFButton';
import './App.css';
import ViewPDFButton from './ViewPDFButton';
import { createSlice } from "@reduxjs/toolkit";

const App = (data , file , userId ) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressbar, setProgressbar] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [inDownloading, setInDownloading] = useState(false);
  const [saveLog, setSaveLog] = useState(false);
  const [ws, setWs] = useState(null);
  const [conversionComplete, setConversionComplete] = useState(false);
  // const [convertedFiles, setConvertedFiles] = useState([]);
  const [stopRequested, setStopRequested] = useState(false);
  const pdfFileName = 'test.pdf';
  const [convertedFiles, setConvertedFiles] = useState(file.name);
// Assuming `convertedFiles` is an array of file names as strings

// Initialize an array to store the results
const indexedSplitFiles = [];
console.log(indexedSplitFiles)

if (Array.isArray(convertedFiles)) {
    // Iterate over each file name in the `convertedFiles` array
    convertedFiles.forEach((file, index) => {
        // Process each file
        console.log(`File ${index}: ${file}`);
        
        // Split the file name by comma and handle it as required
        const splitFileNames = file.split(',');
        console.log(`Split file names for file ${index}:`, splitFileNames);
        
        // Add an object to the results array containing the index and split file names
        indexedSplitFiles.push({
            index: index,
            splitFileNames: splitFileNames
        });
    });

    // Log the new array of indexed split file names
    console.log("Indexed and split files:", indexedSplitFiles);
} else {
    console.log("convertedFiles is not an array");
}




  useEffect(() => {
    if (isLoading && !ws) {
        const socket = new WebSocket('ws://eml.systoolsacademy.com:5001');
        setWs(socket);

        socket.onmessage = event => {
            const data = JSON.parse(event.data);
            if (data.progress !== undefined) {
                setProgress(data.progress);
               
            }
          //   if (data.message !== undefined) {
          //     setConvertedFiles(data.message);
          //     console.log(data.message)
          // }
          if (data.message !== undefined) {
            setConvertedFiles((prevConvertedFiles) => {
                if (Array.isArray(prevConvertedFiles)) {
                    return [...prevConvertedFiles, data.message];
                }
                return [data.message];
            });
            console.log(data.message);
        }

            if(data.progressbarr !== undefined){
              setProgressbar(data.progressbarr);
            }

            if (data.completed) {
                setDownloadUrl(data.downloadUrl);
                setConversionComplete(true);
                setIsLoading(false);
                socket.close();
            }
        };

        socket.onclose = () => {
            setWs(null);
        };
    }

    return () => {
        if (ws) {
            ws.close();
            setWs(null);
        }
    };
}, [isLoading, ws]);

useEffect(() => {
  if (data.message !== undefined) {
      setConvertedFiles(data.message);
      console.log(data.message);
  }
}, [data.message]);

  const onDrop = acceptedFiles => {
    const emlFiles = acceptedFiles.filter(file => file.name.endsWith('.eml'));
    setFiles([...files, ...emlFiles]);
    // setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop});
  
  
  const convertFiles = async () => {
    if (files.length === 0 || isLoading) {
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    files.forEach(file => formData.append('emailFiles', file));
    formData.append('saveLog', saveLog);
    formData.append('stopRequested', stopRequested);

    try {
        // const response = await fetch('https://msg.quickcoupondeals.com/convert', {
      // const response = await fetch('http://localhost:4008/convert', {
      const response = await fetch('http://eml.systoolsacademy.com/convert', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        console.log("result :" , result)
        setDownloadUrl(result.zipPath);

        if (result.convertedFiles) {
          setConvertedFiles(result.convertedFiles);
          console.log("result COnvertedFiles" , result.convertedFiles);
      }
        Swal.fire({
          icon: 'success',
          title: 'Conversion Successful',
          text: 'Your files have been converted successfully.',
        });
        setConversionComplete(true);
      } else {
        throw new Error('Conversion failed');
      }
    } catch (error) {
      console.error('Error converting files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Conversion Error',
        text: 'An error occurred during conversion. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };



  const handleStopClick = async () => {
    setStopRequested(true);

    try {
        // Send a POST request to the server's /stop endpoint
        const response = await fetch('http://localhost:4008/stop', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ stop: true })
        });

        if (response.ok) {
            console.log('Stop request sent successfully');
            // Optionally, show a success message to the user
        } else {
            throw new Error('Failed to send stop request');
        }
    } catch (error) {
        console.error('Error sending stop request:', error);
        // Optionally, show an error message to the user
    }
};



  const downloadConvertedFiles = async () => {
    if (!downloadUrl || isDownloading) {
      return;
    }
  
    setIsDownloading(true);
    // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
    const fullUrl = `http://eml.systoolsacademy.com${downloadUrl}`;
    // const fullUrl = `http://localhost:4008${downloadUrl}`;
    console.log("fullUrl" , fullUrl)
  
    try {
      const response = await fetch(fullUrl, {
        method: 'GET',
      });
  
      if (response.ok) {
        const blob = await response.blob();
        saveAs(blob, 'converted_files.zip');
      } else {
        throw new Error('Download failed');
      }
    } catch (error) {
      console.error('Error downloading files:', error);
      Swal.fire({
        icon: 'error',
        title: 'Download Error',
        text: 'An error occurred during download. Please try again.',
      });
    } finally {
      setIsDownloading(false);
      setDownloadUrl(null);
      window.location.reload();
    }
  };
  
  const removeFile = index => {
    const updatedFiles = [...files];
    updatedFiles[index] = { ...updatedFiles[index], removed: true };
    setFiles(updatedFiles);

    setTimeout(() => {
      const filteredFiles = files.filter((_, i) => i !== index);
      setFiles(filteredFiles);
    }, 300);
  };

  const firstFileName = files.length > 0 ? files[0].name : '';


  // const downloadPDF = async (file , index , splitFileNames) => {
  //   if (downloadUrl) {
  //     const fullUrl = `http://localhost:4008/download${file}`;
  //     console.log(fullUrl)
  //     // const fullUrl = `https://msg.quickcoupondeals.com${downloadUrl}`;
  
  //     try {
  //       setInDownloading(true); // Set loading state to true
  
  //       const response = await fetch(fullUrl, {
  //         method: 'GET',
  //       });

       
  
  //       if (response.ok) {
  //         const blob = await response.blob();
  //         saveAs(blob, 'converted_file.pdf'); 
  //       } else {
  //         throw new Error('Failed to load PDF');
  //       }
  //     } catch (error) {
  //       console.error('Error loading PDF:', error);
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Download Error',
  //         text: 'An error occurred while downloading the PDF. Please try again.',
  //       });
  //     } finally {
  //       setInDownloading(false); 
  //     }
  //   }
  // };
  async function downloadPdf(fileName) {
    try {
        const response = await fetch(`http://eml.systoolsacademy.com/download-file/${fileName}`, {
        // const response = await fetch(`http://localhost:4008/download-file/${fileName}`, {
          method: 'GET',
        });
        console.log("Response :" , response)

        if (!response.ok) {
            throw new Error('Failed to download PDF');
        }

        // Convert the response to a blob
        const blob = await response.blob();

        // Create a URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup: remove the link and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading PDF:', error);
        // Handle the error, e.g., display an error message to the user
    }
}

  

// const viewPDF = async (userId, fileName) => {
//   if (!userId || !fileName) {
//       Swal.fire({
//           icon: 'error',
//           title: 'Invalid Input',
//           text: 'Please provide both user ID and PDF file name.',
//       });
//       return;
//   }

//   setIsLoading(true);

//   try {
//       // Make a request to the server to fetch the PDF file
//       const url = `http://localhost:4008/view-pdf/${encodeURIComponent(userId)}/${encodeURIComponent(fileName)}`;
//       const response = await fetch(url);

//       if (response.ok) {
//           // Open the PDF in a new tab
//           const pdfBlob = await response.blob();
//           const pdfUrl = URL.createObjectURL(pdfBlob);
//           window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab
//       } else {
//           throw new Error('Failed to load PDF');
//       }
//   } catch (error) {
//       console.error('Error loading PDF:', error);
//       Swal.fire({
//           icon: 'error',
//           title: 'Preview Error',
//           text: 'An error occurred while previewing the PDF. Please try again.',
//       });
//   } finally {
//       setIsLoading(false);
//   }
// };

const viewPdf = (fileName) => {
  window.open(`http://eml.systoolsacademy.com/view-pdf/${fileName}`, '_blank');
  // window.open(`http://localhost:4008/view-pdf/${fileName}`, '_blank');
};

  
  return (
    <div>
      
{/* <ViewPDFButton/> */}
      <div className="container" style={{ border: '2px dashed black', cursor: 'pointer', position: 'relative' }}>
        <div className="row justify-content-center">
          <div className="col-8">
          <p className="text-center pt-5 pb-3"></p>
            <div
              {...getRootProps()}
              className="dropzone text-center p-3"
              style={{ cursor: 'pointer' }}
            >
            
              <button className="btn btn-primary">
                <input {...getInputProps()} accept=".eml" />
                <p className="h4 mb-0 fw-bold py-3 text-white px-4 stretched-link">
                  {files.length === 0 ? 'Select Files' : `${firstFileName} ${files.length > 1 ? `+${files.length - 1}` : ''}`}
                </p>
              </button>
              <p className="pt-4 pb-5"></p>
              {/* Progress bar */}
              {isLoading && (
                // <ProgressBar  striped variant="info" animated now={100} label={`Files converting...`} />
               

            
    <ProgressBar
        striped
        variant="info"
        animated
        now={progressbar}
        label={`${progressbar}%`}
    />
)}

{isLoading && (
  <p className='fw-bold mt-3'>{progress}</p>
)}
{isLoading && (
  <p className='fw-bold mt-3'>Files are converting.....</p>
)}

             
            </div>
          </div>
        </div>
      </div>

      {(files.length > 0 && !conversionComplete) && (
        <div className="row mt-3">
          <div className="col text-center">
          <button
  className="btn btn-primary btn-block custom-convert-button"
  onClick={convertFiles}
  disabled={isLoading}
>
  {isLoading ? (
    <>
      <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
      Converting...
    </>
  ) : (
    <>
      <span className="me-1" aria-hidden="true">&#x21a6;</span>
      Convert
    </>
  )}
</button>

{/* {isLoading && (
   <button
   className="btn btn-danger btn-block custom-stop-button ms-4"
   onClick={handleStopClick}
   disabled={!isLoading}
>
   Stop Conversion
</button>
)} */}

<label className="form-check-label mt-2 float-end">
        <input
          type="checkbox"
          className="form-check-input"
          checked={saveLog}
          onChange={e => setSaveLog(e.target.checked)}
        />
        &nbsp; Save Conversion Log
      </label>
          </div>
        </div>
      )}

      {conversionComplete && (
        <div className="row mt-3">
          <div className="col text-center">
            <button
              className="btn btn-success btn-block custom-download-button"
              onClick={downloadConvertedFiles}
              disabled={isDownloading}
            >
              <FontAwesomeIcon icon={faDownload} className="mr-1" />
               &nbsp;  {isDownloading ? 'Downloading...' : 'Download Converted Files'}
            </button>
          </div>
        </div>
      )}

      {isDownloading && (
        <div className="text-center mt-3">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div>


{/* <PdfViewer fileName="test.pdf" /> */}
{/* <PdfViewer/> */}
      </div>
{files.length > 0 && (
  <div>
    <p className='fw-bold'>
      <FontAwesomeIcon icon={faFileUpload} className="me-2" />
      Total Files Uploaded: {files.length}
    </p>
  </div>
)}

      {files.length > 0 && (
        <div className="container mt-4">
          <div className="row">
            <div className="col" style={{ maxHeight: '200px', overflowX: 'auto', overflowY: 'scroll' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">File Name</th>
                    <th scope="col">File Size</th>
                    <th scope="col">Progress</th>
                    <th scope="col">Actions</th>
                    {conversionComplete && (
                      <>
                        <th scope="col">Preview Pdf</th>
                        <th scope="col">Download Pdf</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
  {files.map((file, index) => {
    const splitFileNames = indexedSplitFiles.find(item => item.index === index)?.splitFileNames || [];
    return (
      <tr key={index} className={file.removed ? "fade-out" : ""}>
        <td>
          <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
          {splitFileNames.length > 0 ? (
            <ul>
              {splitFileNames.map((name, idx) => (
                <li key={idx}>{name}</li>
              ))}
            </ul>
          ) : (
            <div>{file.name}</div>
          )}
        </td>
        <td>{(file.size / 1024).toFixed(2)} KB</td>
        <td>{conversionComplete ? 'Done' : 'Pending'}</td>
        <td>
          {conversionComplete ? (
            <span className="text-success">&#10003; File Converted</span>
          ) : (
            <button className="btn btn-danger btn-sm" onClick={() => removeFile(index)}>
              <FontAwesomeIcon icon={faTrash} className="mr-1" />
            </button>
          )}
        </td>
        {conversionComplete && (
          <>
            <td>
            <button
      className="btn btn-primary btn-sm"
      onClick={() => viewPdf(splitFileNames)}
    >
      View PDF
    </button>
            </td>
            {/* <td>
              <button
                className="btn btn-success btn-sm"
                onClick={() => downloadPDF(splitFileNames[index])}
                disabled={isDownloading}
              >
                {inDownloading ? (
                  <>
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <span className="ms-1">Downloading...</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faDownload} className="mr-1" /> Download
                  </>
                )}
              </button>
            </td> */}
            <td>
  <button
    className="btn btn-success btn-sm"
    onClick={() => downloadPdf(splitFileNames)
     
    }


    disabled={isDownloading}
  >
    {inDownloading ? (
      <>
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <span className="ms-1">Downloading...</span>
      </>
    ) : (
      <>
        <FontAwesomeIcon icon={faDownload} className="mr-1" /> Download
      </>
    )}
  </button>
</td>

          </>
        )}
      </tr>
    );
  })}
</tbody>

              </table>
            </div>
          </div>
        </div>
      )}


      
    </div>
  );
};

export default App;

