import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEye, faDownload, faTimes , faTrash} from '@fortawesome/free-solid-svg-icons';


const ViewPDFButton = ({ fileName }) => {
    const [isLoading, setIsLoading] = useState(false);

    const viewPDF = async () => {
        if (!fileName) {
            Swal.fire({
                icon: 'error',
                title: 'File Not Specified',
                text: 'Please provide a valid PDF file name to view.',
            });
            return;
        }

        setIsLoading(true);

        try {
            // Make a request to the server to fetch the PDF file
            const url = `http://localhost:4008/view-pdf/${encodeURIComponent(fileName)}`;
            const response = await fetch(url);

            if (response.ok) {
                // Open the PDF in a new tab
                const pdfBlob = await response.blob();
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank'); // Opens the PDF in a new tab
            } else {
                throw new Error('Failed to load PDF');
            }
        } catch (error) {
            console.error('Error loading PDF:', error);
            Swal.fire({
                icon: 'error',
                title: 'Preview Error',
                text: 'An error occurred while previewing the PDF. Please try again.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <button className="btn btn-primary btn-sm" onClick={viewPDF} disabled={isLoading}>
            {isLoading ? (
                <>
                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    Loading...
                </>
            ) : (
                <>
                    <FontAwesomeIcon icon={faEye} className="me-1" />
                    View PDF
                </>
            )}
        </button>
    );
};

export default ViewPDFButton;
